/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://qifgghf25vgvbafhidcfrrslbq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-zlsafidibnhtjhneumkxsbtb2y",
    "aws_cognito_identity_pool_id": "eu-central-1:022bce94-6eb1-4c21-b95f-6c0badea32af",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_9oRhuQYdB",
    "aws_user_pools_web_client_id": "24d62f1cgq0qktbjouqdikengh",
    "oauth": {}
};


export default awsmobile;
